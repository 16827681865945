<template>
    <main>
        <page-hero site="Error 404"/>
        <div class="container-fluid">
            <div class="section-info text-center">
                <h3>Ups... Ta strona nie została znaleziona.</h3>
                <h5>Może Twoje umiejętności szukania stron nie są wystaczające dobre?</h5>
                <router-link :to="{name: 'home'}" class="btn primary-button"><i class="fa fa-home"></i> Strona główna</router-link>
            </div>
        </div>
    </main>
</template>

<script>
export default {
name: "Error404"
}
</script>

<style scoped>

</style>